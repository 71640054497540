import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Box, Stack, IconButton, Typography, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BigNumber } from 'ethers'
import styles from './MintGen3D.module.css';
import WalletConnect from '../../components/WalletButton/WalletButton';
import useActiveWeb3React from '../../hook/useActiveWeb3React';
import { getERC721Contract } from '../../utils/contractHelper';

import config from '../../Config/config.json';

const PrettoSlider = styled(Slider)({
  color: '#FDAB45',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const titleStyle = {
  color: 'white',
  fontSize: '30px',
  fontWeight: '500'
}

const MintGen3D = () => {
  const { account, library } = useActiveWeb3React();
  const [amount, setAmount] = useState<number>(1);
  const [totalSupply, setTotalSupply] = useState<number>(0);
  const [maxSupply, setMaxSupply] = useState<number>(0);
  const [useHigherGas, setUseHigherGas] = useState<boolean>(true);

  const handleSlider = (event: any, newValue: any) => {
    setAmount(newValue);
  }

  const mint = async () => {
    try {
      const mintContract = getERC721Contract(config.nobugen3d_contract, library?.getSigner())
      const mintCost = await mintContract.mintCost(account);

      const gasEstimated = await mintContract.estimateGas.mint(amount, { value: mintCost.mul(amount).toString() });
      let tx;
      if (useHigherGas) {
        const gas = Math.ceil(gasEstimated.toNumber() * 1.5);
        const gasNumber = BigNumber.from(gas);
        tx = await mintContract.mint(amount, {
          value: mintCost.mul(amount).toString(),
          gasLimit: gasNumber,
        });
      } else {
        tx = await mintContract.mint(amount, {
          value: mintCost.mul(amount).toString()
        });
      }
      await tx.wait();

      toast("Mint Success!", { position: "bottom-left", type: "success" });
    }
    catch (err: any) {
      console.log("err::", err);
      toast(err?.error?.data.message || err.message, { position: "bottom-left", type: "error" });
    }
    fetchData();
  }

  const fetchData = useCallback(() => {
    try {
      const mintContract = getERC721Contract(config.nobugen3d_contract, library);
      mintContract.totalSupply().then((supply: any) => {
        setTotalSupply(supply.toNumber());
      });
      mintContract.maxSupply().then((max: any) => {
        setMaxSupply(max.toNumber());
      });

    }
    catch (err: any) {
      console.log('err:', err);
    }
  }, [library]);

  useEffect(() => {
    fetchData();
  }, [fetchData])

  return (
    <>
      <Box className={styles.main}>
        {/* <img style={{width:'70%', margin:'0 auto'}} src='./assets/img/mintGen3d/Let.png' /> */}
        <Typography sx={{ fontSize: '10vw', color: 'white', textAlign: 'center' }}>Let's Fly!</Typography>
        <Box className={styles.section}>
          <Stack className={styles.left} direction='column' spacing={2} sx={{ width: '30%' }}>
            <Typography sx={{ color: 'white', fontSize: '5vw', textAlign: 'center' }}>299 CRO</Typography>
            <Typography sx={{ color: 'white', fontSize: '5vw', textAlign: 'center' }}>275 CRO WL</Typography>
          </Stack>
          <img style={{ width: '30%' }} src='./assets/img/mintGen3d/Pilot.png' alt='pilot' />
          <Stack className={styles.right} direction='column' spacing={2} sx={{ width: '30%' }}>

            <Typography sx={{ color: 'white', fontSize: '5.5vw', textAlign: 'center' }}>199 CRO</Typography>
            <Typography sx={{ color: 'white', fontSize: '3vw', textAlign: 'center' }}>NoBu NFT Holders</Typography>
          </Stack>
        </Box>

        <img style={{ width: '50%', margin: '0 auto' }} src='./assets/img/mintGen3d/In 3D.png' alt='in 3d' />
        <Typography sx={{ color: 'white', fontSize: '3vw', textAlign: 'center' }}><a href="https://forms.gle/LhP6J8umE1BvXHxq8" target="_blank">BuddieBrix Claim form</a></Typography>

        <Stack direction='row' alignItems='center' spacing={3} justifyContent='center'>
          <Typography sx={titleStyle}>{totalSupply} / </Typography>
          <Typography sx={titleStyle}>{maxSupply}</Typography>
        </Stack>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {
            !account &&
            <Box sx={{ paddingTop: '80px', paddingBottom: '80px' }}>
              <WalletConnect>CONNECT</WalletConnect>
            </Box>
          }
          {
            account &&
            <Box sx={{ padding: '20px' }}>
              <PrettoSlider
                value={amount}
                onChange={handleSlider}
                sx={{ marginTop: '25px' }}
                min={1}
                max={10}
                step={1}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                defaultValue={1}
              />
              <div className="d-flex flex-row justify-content-center mb-15" style={{ gap: '15px', marginTop: '10px' }}>
                <input
                  className="mr-10"
                  type="checkbox"
                  id="mdcHigherGas"
                  value="Use higher gas to reduce mint failure chance"
                  checked={useHigherGas}
                  onChange={(e) => setUseHigherGas(e.target.checked)}
                />
                <label className="text-white">
                  Use better gas setting to reduce chance of failed tx
                </label>
              </div>
              <IconButton className={styles.mint} onClick={mint}>
                <img style={{ width: '40%' }} src='./assets/img/mintGen3d/Mint Button.png' alt='mint' />
              </IconButton>
            </Box>
          }
        </Box>
        <Box className={styles.bottom_section}>
          <img src='./assets/img/mintGen3d/Chalk 1.png' alt='chalk1' />
          <img src='./assets/img/mintGen3d/Chalk 1.png' alt='chalk2' />
        </Box>
      </Box>
    </>
  );
}

export default MintGen3D;