import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { Contract } from '@ethersproject/contracts'
import ERC721ABI from '../contract/ERC721.json';

export const getContract = (abi: any, address: string, signer?: Signer | Provider) => {
  const signerOrProvider = signer;
  return new Contract(address, abi, signerOrProvider)
}

export const getERC721Contract = (address: string, signer?: Signer | Provider) => {
  return getContract(ERC721ABI, address , signer)
}