import { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box, Typography, IconButton, Stack, Slider } from '@mui/material'
import { styled } from '@mui/material/styles';

import { ethers, BigNumber } from 'ethers'
import WalletConnect from '../../components/WalletButton/WalletButton';
import useActiveWeb3React from '../../hook/useActiveWeb3React';
import { getERC721Contract } from '../../utils/contractHelper';

import styles from './MintNobu.module.css';

import config from '../../Config/config.json';

const PrettoSlider = styled(Slider)({
  color: '#FDAB45',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const titleStyle = {
  color: 'white',
  fontSize: '30px',
  fontWeight: '500'
}

const MintNobu = () => {
  const { account, library } = useActiveWeb3React();
  const [value, setValue] = useState<number>(1);
  const [cost, setCost] = useState<any>();
  const [totalSupply, setTotalSupply] = useState<number>(0);
  const [maxSupply, setMaxSupply] = useState<number>(0);
  const [useHigherGas, setUseHigherGas] = useState<boolean>(true);

  const handleSlider = (event: any, newValue: any) => {
    setValue(newValue);
    // fetchData();
  }

  const mint = async () => {
    try {
      const mintContract = getERC721Contract(config.mint_contract, library?.getSigner())
      const totalCost = value * 69;
      const cost = ethers.utils.parseEther(totalCost.toString());

      const gasEstimated = await mintContract.estimateGas.mint(value, { value: cost });
      let tx;
      if (useHigherGas) {
        const gas = Math.ceil(gasEstimated.toNumber() * 1.5);
        const gasNumber = BigNumber.from(gas);
        tx = await mintContract.mint(value, {
          value: cost,
          gasLimit: gasNumber,
        });
      } else {
        tx = await mintContract.mint(value, {
          value: cost,
        });
      }
      await tx.wait();
      toast("Mint Success!", { position: "bottom-left", type: "success" });
    }
    catch (err: any) {
      console.log("err::", err);
      toast(err?.error?.data.message || err.message, { position: "bottom-left", type: "error" });
    }
  }

  const fetchData = useCallback(() => {
    try {
      const mintContract = getERC721Contract(config.mint_contract, library);
      mintContract.totalSupply().then((supply: any) => {
        setTotalSupply(supply.toNumber());
        console.log('supply:', supply.toNumber())
      });
      mintContract.maxSupply().then((max: any) => {
        setMaxSupply(max.toNumber());
        console.log('max supply:', max.toNumber())
      });
      mintContract.cost().then((cost: any) => {
        setCost(parseInt(ethers.utils.formatEther(cost).toString()));
        console.log("cost:", cost.toString())
      })

    }
    catch (err: any) {
      console.log('err:', err);
    }
  }, [library]);

  useEffect(() => {
    fetchData();
  }, [fetchData])

  console.log('account:', account)

  return (
    <>
      <Box className={styles.main}>
        {/* <img style={{width:'80%', margin:'auto'}} src='./assets/img/MintNft/Raid.png' /> */}
        <Typography sx={{ textAlign: 'center', fontSize: '10vw', color: 'white' }}>Let's Raid!</Typography>
        <Box className={styles.section}>

          <Typography sx={{ textAlign: 'center', fontSize: '5vw', color: 'white', width: '30%' }}>{cost} CRO</Typography>
          <img src='./assets/img/MintNft/Viking.png' alt='viking' />
          <Stack direction='column' alignItems='center'>
            <Stack direction='row' alignItems='center' spacing={3} justifyContent='center'>
              <Typography sx={titleStyle}>{totalSupply}/</Typography>
              <Typography sx={titleStyle}>{maxSupply}</Typography>
            </Stack>
            {
              !account &&
              <WalletConnect>CONNECT</WalletConnect>
            }
            {
              account &&
              <Box>
                <PrettoSlider
                  value={value}
                  onChange={handleSlider}
                  sx={{ marginTop: '25px', marginBottom: '15px' }}
                  min={1}
                  max={10}
                  step={1}
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={1}
                />
                <div className="d-flex flex-row justify-content-center mb-15" style={{ gap: '15px' }}>
                  <input
                    className="mr-10"
                    type="checkbox"
                    id="mdcHigherGas"
                    value="Use higher gas to reduce mint failure chance"
                    checked={useHigherGas}
                    onChange={(e) => setUseHigherGas(e.target.checked)}
                  />
                  <label className="text-white">
                    Use better gas setting to reduce chance of failed tx
                  </label>
                </div>
                <IconButton onClick={mint}>
                  <img style={{ width: "60%" }} src='./assets/img/MintNft/Mint Button.png' alt='mintbutton' />
                </IconButton>
              </Box>

            }

          </Stack>
        </Box>
        <Box className={styles.crypto}>
          <img src='./assets/img/MintNft/Gold Stack.png' alt='gold stack' />
          <img src='./assets/img/MintNft/Silver Stack.png' alt='silver' />
          <img src='./assets/img/MintNft/Bronze Stack.png' alt='bronze' />
        </Box>
      </Box>
    </>
  );
}


export default MintNobu;