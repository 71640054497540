



const Roadmap = () => {
  return(
    <>
      <img style={{width:'100%',margin:'auto'}} src='./assets/img/Roadmap/Roadmap.png' alt='roadmap' />
    </>
  );
}


export default Roadmap;